<div [formGroup]="form" class="my-2">
  <label for="department" class="form-label">{{'Forms.' + label | translate}}</label>
  <ng-container *ngIf="!is_multi">
    <div class="flex justify-content-center ">
      <select [value]="selectedValue" class="form-select" id="schoolHub" [ngClass]="{
        'errorInput': form.get(form_control_name)?.errors && form.get(form_control_name)?.touched,
      }" required [disabled]="isDisabled" (change)="onChange($any($event.target).value)">
        <option [value]="''" disabled selected >{{'Forms.Select' | translate}}</option>
        <option *ngFor="let item of dataList" [value]="item.id || item.value || item">
          {{item[subItem] || item}}
        </option>
      </select>
    </div>
  </ng-container>

  <ng-container *ngIf="is_multi">
    <label class="form-label">{{'Forms.' + label | translate}}</label>
    <p-multiSelect
      [class.ng-invalid.ng-dirty]="form.get(form_control_name)?.invalid &&
      form.get(form_control_name)?.dirty && form.get(form_control_name)?.touched"
      [formControlName]="form_control_name"
      [options]="dataList"
      [disabled]="isDisabled"
      [optionLabel]="subItem"
      optionValue="id"
      placeholder="{{'Forms.Choose Classes' | translate}}" />
  </ng-container>


</div>
