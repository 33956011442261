import { HttpClient, HttpContext, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.development';

const serviceSecretOptions = {
  headers: new HttpHeaders({
    // 'Service-Secret': 'service_001',
  })
}

const formDataHeaderOptions = {
  headers: new HttpHeaders({
    // 'Service-Secret': 'service_002',
    "mimeType": "multipart/form-data",
    "processData": 'false',
    // "Content-Type": "multipart/form-data"
  })
}

const contentTypeFormDataHeaderOptions = {
  headers: new HttpHeaders({
    // 'Service-Secret': 'service_002',
    "mimeType": "multipart/form-data",
    "processData": 'false',
    "Content-Type": "multipart/form-data"
  })
}

const jsonDataHeaderOptions = {
  headers: new HttpHeaders({
    // 'Service-Secret': 'service_001',
    "mimeType": "application/json",
    "processData": 'false',
    // "Content-Type": "application/json"
  })
}

export class ParameterClass {
  constructor(public ParameterName: any, public ParameterValue: any) { }
}

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  baseUrl: string = environment.basicUrl;
  version: string = environment.version

  constructor(private httpclient: HttpClient) { }

  public CallRequestList(url: string, parameters?: string[]) {
    var Url: string = `${this.baseUrl}/${this.version}/${url}`;
    if (parameters != null && parameters.length > 0) {
      Url += '?';
      for (var _i = 0; _i < parameters.length; _i++) {
        if (_i != 0) Url += '&&';
        Url += parameters[_i];
      }
    }
    return this.httpclient.get<any>(Url, serviceSecretOptions);
  }

  public createFormData(url: string, model: any) {
    var Url: string = `${this.baseUrl}/${this.version}/${url}`;
    return this.httpclient.post(Url, model, formDataHeaderOptions);
  }

  public createFormDataWithParams(url: string, model: any, parameters?: string[]) {
    var Url: string = `${this.baseUrl}/${this.version}/${url}`;
    if (parameters != null && parameters.length > 0) {
      Url += '?';
      for (var _i = 0; _i < parameters.length; _i++) {
        if (_i != 0) Url += '&&';
        Url += parameters[_i];
      }
    }
    return this.httpclient.post(Url, model, formDataHeaderOptions);
  }

  public createJsonData(url: string, model: any) {
    var Url: string = `${this.baseUrl}/${this.version}/${url}`;
    return this.httpclient.post(Url, model, jsonDataHeaderOptions);
  }
  public put(url: string, id: string) {
    const Url: string = `${this.baseUrl}/${this.version}/${url}/${id}`;
    return this.httpclient.put(Url, id);
  }

  public PutFormData(url: string, model: any, parameters?: string[]) {
    var Url: string = `${this.baseUrl}/${this.version}/${url}`;
    if (parameters != null && parameters.length > 0) {
      Url += '?';
      for (var _i = 0; _i < parameters.length; _i++) {
        if (_i != 0) Url += '&&';
        Url += parameters[_i];
      }
    }
    return this.httpclient.post(Url, model, formDataHeaderOptions);
  }

  public PutFormDataWithContentType(url: string, model: any, parameters?: string[]) {
    var Url: string = `${this.baseUrl}/${this.version}/${url}`;
    if (parameters != null && parameters.length > 0) {
      Url += '?';
      for (var _i = 0; _i < parameters.length; _i++) {
        if (_i != 0) Url += '&&';
        Url += parameters[_i];
      }
    }
    return this.httpclient.post(Url, model, contentTypeFormDataHeaderOptions);
  }

  public PutJsonData(url: string, model: any, parameters?: string[]) {
    var Url: string = `${this.baseUrl}/${this.version}/${url}`;
    if (parameters != null && parameters.length > 0) {
      Url += '?';
      for (var _i = 0; _i < parameters.length; _i++) {
        if (_i != 0) Url += '&&';
        Url += parameters[_i];
      }
    }
    return this.httpclient.post(Url, model, jsonDataHeaderOptions);
  }

  public CreateRequestParameters(parmas: ParameterClass[]): string[] {
    var finalparams: string[] = [];
    for (var _i = 0; _i < parmas.length; _i++) {
      finalparams.push(parmas[_i].ParameterName + '=' + parmas[_i].ParameterValue);
    }
    return finalparams;
  }

  //Delete
  public delete(url: string) {
    var Url: string = `${this.baseUrl}/${this.version}/${url}`;
    return this.httpclient.delete(Url, serviceSecretOptions);
  }
}
