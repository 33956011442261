import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss']
})
export class FormSelectComponent {
  @Input() form!: UntypedFormGroup;
  @Input() placeholder!: string;
  @Input() form_control_name: any;
  @Input() form_control_filter: any;
  @Input() validation_type!: string;
  @Input() dataList: any[] = [];
  @Input() is_multi: boolean = false;
  @Input() optional: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() optionLabel!: string;;
  @Input() title!: string;
  @Input() label!: string;
  @Input() subItem!: string;
  @Input() selectedValue: string = ''; // Selected value from the parent


  @Output() onControlChange = new EventEmitter();

  constructor() { }
  toLang(param: { [key: string]: string }) {
    return param['en']
  }
  get control() {
    return this.form?.controls[this.form_control_name] as FormControl
  }

  onChange(control_ID: any) {
    this.onControlChange.emit({ key: this.form_control_name, value: control_ID });
    this.control.setValue(control_ID);
  }
}
